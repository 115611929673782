<template>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="small-box pointer" v-bind:class="hasLastCarPhoto ? 'bg-image text-gray-light' : 'bg-blue-active'"
             v-bind:style="hasLastCarPhoto ?
             'background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.45)), url(' + car_item.main_photo + ');' +
             'background-position: center; background-repeat: no-repeat; background-size: cover;' : ''"
             v-on:click="$router.push('/cars')">
            <div class="inner">
                <h3>{{ cars_count }}</h3>
                <h5>Автомобилей</h5>
            </div>
            <div v-if="!hasLastCarPhoto" class="icon">
                <i class="fas fa-car-alt"></i>
            </div>
            <a class="small-box-footer" title="Перейти" style="padding-top: 6px; padding-bottom: 6px;">
                <i class="fa font-scale fa-arrow-circle-right"></i>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'cars-box',

        props: {
            cars_count: {
                type: Number,
                required: true,
                default: 0,
            },
            car_item: {
                type: [Object, null],
                required: false,
            },
        },

        computed: {
            hasLastCarPhoto: function () {
                return this.car_item && this.car_item.main_photo;
            },
        },
    }
</script>